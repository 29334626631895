import type { BlockIntro } from 'types/block-intro';
import React from 'react';
import { Wrap, Button } from '@leafwell/components';
import Figure from './figure';
import HeadingBody from './heading-body';

const Intro: React.FC<BlockIntro> = ({
  title,
  description,
  topIcon,
  button,
  sectionId,
  alignment = 'center',
}) => {
  const isCenter = alignment === 'center';
  return (
    <Wrap
      id={sectionId}
      className={[
        '!gap-y-6 justify-items-start',
        isCenter ? 'md:justify-items-center md:text-center' : '',
      ].join(' ')}
      size="small"
    >
      {topIcon && <Figure {...topIcon} className="w-6" />}
      {title?.length > 0 && (
        <HeadingBody
          className="font-semibold text-center lg:max-w-3xl"
          level="2"
          tagName="h2"
          title={title}
        />
      )}
      {description?.length > 0 && (
        <HeadingBody
          level="6"
          tagName="p"
          title={description}
          className="text-center text-gray-700 lg:max-w-5xl"
        />
      )}
      {button && (
        <Button
          href={button.url}
          label={button.title}
          rounded={true}
          target={button.target}
          filled={true}
          variant="secondary"
        />
      )}
    </Wrap>
  );
};

export default Intro;
